//骊阅文件库
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.user_name }}
        </span>
        &nbsp; | &nbsp;
      </div>
      <span class="font-weight-bold text-dark" style="opacity: 0.5">
        <span v-for="team in str.data.tags" :key="team">
          {{ team }}
        </span>
        <span v-if="str.data.tags.length == 0">未分类</span>
      </span>
      <!-- <span class="opacity-5">来自{{ tab ? "自选个股" : "自选标签" }}</span> -->
    </v-row>

    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
      @mousedown.stop
      @touchstart.stop
      @dragstart.stop
    >
      {{ str.data.name }}
    </v-list-item-title>
    <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      <a
        href="javascript:;"
        @click="$commonHelpers.previewFile(str.data.id)"
        class="text-xs font-weight-normal text-typo mr-2"
        >预览</a
      >
      <a
        href="javascript:;"
        @click="$commonHelpers.downloadFile(str.data.id)"
        class="text-xs font-weight-normal text-typo mr-2"
        >下载</a
      >
    </v-list-item-subtitle>

    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <!-- <v-btn-toggle dense borderless v-model="tab" class="zc-toggle"> -->
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-white
            me-1
            font-weight-bold
            px-1
            py-1
            mb-1
            badge-font-size
          "
          v-for="sector in str.data.races"
          :color="colorData[sector.level]"
          :key="sector.id"
          @click="$stockHelpers.openTagDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-blue
            me-1
            font-weight-bold
            px-1
            py-1
            mb-1
            badge-font-size
          "
          v-for="sector in str.data.stocks"
          :key="sector.id"
          @click="$stockHelpers.openStockDetail(sector.id)"
          >{{ sector.name }}</v-btn
        >
        <!-- </v-btn-toggle> -->
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
export default {
  props: ["str", "tab"],
  mounted() {
    console.log(this.str);
  },
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  methods: {},
};
</script>
