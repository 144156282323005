<template>
  <div>
    <v-container
      fluid
      class="px-2 py-2"
      style="display: flex; justify-content: center; width: 100%"
    >
      <v-row
        style="display: flex; width: 100%; margin: 0; align-items: baseline"
        justify="center"
      >
        <v-col cols="8" class="z-index-2 mt-2 d-flex justify-content-center">
          <v-row style="width: 100%">
            <v-row style="width: 100%; position: relative" id="tableContent">
              <v-col cols="12">
                <tableShow @getUpdateTime="getUpdateTime"></tableShow>
              </v-col>
            </v-row>
            <v-row style="width: 100%; position: relative" id="tableContents">
              <v-col cols="12">
                <tagViewPoint></tagViewPoint>
              </v-col>
            </v-row>
            <HomeContent />
          </v-row>
        </v-col>

        <v-col cols="4" class="z-index-2">
          <div class="pa-2 mt-2" ref="homeSection">
            <HomeSection />
            <!-- <v-card class="border-radius-md mt-2" style="margin-top: 10px">
              
            </v-card> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Tag from "@/api/tag.js";
import Bus from "@/components/Bus/bus.js";
import User from "@/api/user.js";
import DefaultcoverUrl from "@/assets/luozi.jpeg";
import { FadeTransition } from "vue2-transitions";
import ShowMoreText from "@/components/ShowMoreText";
import StockRecommend from "./StockRecommend";
import MomTimeLine from "./MomTimeLine";
import StockRate from "./StockRate";
import TagRate from "./TagRate";
import TradeHistory from "./TradeHistory";
import TagReview from "./TagReview";
import StockPattern from "./StockPattern";
import HomeSection from "@/components/HomePage/HomeSection.vue";
import HomeContent from "@/components/HomePage/HomeContent.vue";
import tagViewPoint from "@/components/HomePage/tagViewPoint.vue";
import tableShow from "@/components/HomePage/tableShow.vue";
export default {
  name: "Dashboard",
  components: {
    FadeTransition,
    ShowMoreText,
    StockRate,
    TagRate,
    TradeHistory,
    TagReview,
    StockRecommend,
    MomTimeLine,
    StockPattern,
    HomeSection,
    HomeContent,
    tableShow,
    tagViewPoint,
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      pageHeight: 1000,
      tradingHistory: [],

      coverLoaded: false,
      cover: {
        copyright: "洛子峰，海拔8516米，世界第四高峰",
        title: "洛子峰",
        url: DefaultcoverUrl,
      },

      currTab: 0,
      tagList: [],
      today: moment().format("YYYY-MM-DD"),
      yesterday: moment().subtract(1, "days").format("YYYY-MM-DD"),
      twoDaysAgo: moment().subtract(2, "days").format("YYYY-MM-DD"),
      updated_on: "",
      infoList: [],
    };
  },
  mounted() {
    this.pageHeight = window.screen.availHeight;

    this.getCover();
    this.getTagList();
  },
  methods: {
    getCover() {
      User.getCover()
        .then((res) => {
          //console.log(res.data);
          this.cover = res.data;
          this.coverLoaded = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openCover() {
      Bus.$emit("previewFile", {
        url: this.cover.url.replace("&pid=hp", ""),
        name: this.cover.title + ".jpg",
      });
    },

    getTagList() {
      Tag.getTagsList()
        .then((res) => {
          this.tagList = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {});
    },

    cellClassName(row) {
      if (row.level) {
        if (row.level === 1) {
          return "tab-1";
        } else if (row.level === 2) {
          return "tab-1";
        } else if (row.level === 3) {
          return "tab-3";
        }
      } else {
        return "tab-4";
      }
      return null;
    },
    getUpdateTime(time) {
      this.updated_on = time;
    },
  },
  watch: {
    // currTab(val){
    // 	console.log(val);
    // },
  },
};
</script>
<style>
.tab-1 {
  background-color: #f4685d;
}
.tab-2 {
  background-color: #ffad33;
}
.tab-3 {
  background-color: #32bfa7;
}
.tab-4 {
  background-color: #9db7c6;
}
</style>
