<template>
  <div style="width: 100%" id="infoStreams">
    <v-card color="white" class="border-radius-sm mt-2">
      <v-card-title
        class="d-flex justify-space-between align-center px-0 py-0"
        style="position: sticky; top: 56px; z-index: 1; background: #fff"
        ><v-btn-toggle borderless>
          <v-btn text class="font-weight-bold text-h6">标签观点</v-btn>
        </v-btn-toggle>
      </v-card-title>

      <v-card-text class="pt-0">
        <v-list class="pt-0" style="min-height: 100px" ref="listBox">
          <!-- <v-list-item-group> -->
          <div
            v-if="infoList.length == 0 && !tableLoading"
            style="
              width: 100%;
              height: calc(100% - 50px);
              flex-direction: column;
            "
            class="d-flex justify-center align-center"
          >
            <img src="@/assets/img/empty.png" />
            <div style="color: #bfcee0">请先收藏个股或标签</div>
          </div>

          <template v-for="(str, index) in infoList" slot="default">
            <v-list-item :key="index.id" class="px-0" selectable>
              <v-list-item-content class="pb-0">
                <v-row
                  class="d-flex align-center"
                  no-gutters
                  @click="preview(str.content, str.title + '.direct')"
                >
                  <div>
                    <div>
                      <span>{{ str.user_name }}</span>
                      <b> | </b>
                      <span
                        class="font-weight-bold text-dark"
                        style="opacity: 0.5"
                        >骊阅标签观点
                      </span>
                    </div>
                    <span class="font-weight-bold text-subtitle-2"
                      >{{ str.from }}
                    </span>
                  </div>
                </v-row>
                <v-list-item-title
                  class="font-weight-bold mt-1"
                  style="
                    overflow: inherit;
                    white-space: normal;
                    line-height: 24px;
                  "
                  @click="preview(str.content, str.title + '.direct')"
                >
                  {{ str.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-row
                    no-gutters
                    v-html="str.abstract"
                    class="desc"
                    style="-webkit-line-clamp: none"
                  ></v-row>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="py-2 d-flex align-center justify-space-between"
                  style="color: #191a1d; line-height: 22px"
                >
                  <v-row no-gutters class="d-flex align-center">
                    <v-btn
                      elevation="1"
                      small
                      :ripple="false"
                      height="21"
                      class="
                        border-radius-sm
                        text-xxs text-white
                        me-1
                        font-weight-bold
                        px-1
                        py-1
                        badge-font-size
                      "
                      v-for="sector in str.races"
                      :color="colorData[sector.level]"
                      :key="sector.id"
                      @click="$stockHelpers.openTagDetail(sector.id)"
                      >{{ sector.name }}</v-btn
                    >
                  </v-row>
                  <div>{{ str.updated_at }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="infoList.length && index < infoList.length - 1"
              :key="`divider-${index}`"
              light
              style="opacity: 0.5"
            ></v-divider>
          </template>
          <vxe-pager
            :current-page.sync="page"
            :page-size.sync="perPage"
            :total="total"
            @page-change="handlePageChange"
          />
          <!-- <v-skeleton-loader
                  v-if="tableLoading && page == 1"
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader> -->
          <!-- </v-list-item-group> -->
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";

import viewpoints from "./TypeComponents/viewpoints.vue";
import User from "@/api/user";
export default {
  name: "HomeContent",
  components: {
    viewpoints,
  },
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
      tableLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      infoList: [],

      page: 1,
      noData: true,
      scrollTopHeight: 0,
      total: 0,
      countOfRender: 0,
      data: [],
      once: 50,
      no_race: "",
      search: "",
      show_all: 2,
      currentBtn: "",
      childBtn: "",
      perPage: 5,
      totalPage: 0,
    };
  },
  methods: {
    getInfoList() {
      this.tableLoading = true;

      let formData = {
        perPage: this.perPage,
        page: this.page,
      };
      User.labelView(formData)
        .then((res) => {
          if (res.data.data.length == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }

          // this.infoList = this.infoList.concat(res.data);
          this.countOfRender = 0;
          this.total = res.data.total;
          this.infoList = res.data.data;
          // this.loop(done);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handlePageChange($event) {
      console.log($event);
      this.page = $event.currentPage;
      this.perPage = $event.pageSize;
      this.getInfoList();
    },

    preview(content, title) {
      Bus.$emit("previewFile", { url: content, name: title });
    },
  },
  created() {
    this.getInfoList();
  },
  mounted() {},
  computed: {},
  watch: {},
  directives: {},
  filters: {},
  props: {
    tagId: {
      default: "",
      type: String,
    },
    stockId: {
      default: "",
      type: String,
    },
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>

<style>
.fold {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}
</style>
