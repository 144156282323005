//外媒
<template>
  <v-list-item-content class="pb-0">
    <v-list-item-title v-html="str.data.user"></v-list-item-title>
    <v-list-item-title
      @mousedown.stop
      @touchstart.stop
      @dragstart.stop
      v-html="str.data.content"
      style="white-space: pre-wrap; line-height: 1.5"
    ></v-list-item-title>

    <a
      href="javascript:void(0);"
      @click="pr(str)"
      class="pr py-2"
      v-if="str.data.content"
      >翻译原文</a
    >

    <v-progress-circular
      indeterminate
      color="primary"
      v-if="str.data.loading"
    ></v-progress-circular>

    <v-list-item-subtitle
      v-if="str.data.content_cn"
      @mousedown.stop
      @touchstart.stop
      @dragstart.stop
      style="white-space: pre-wrap; line-height: 1.5"
      >{{ str.data.content_cn }}</v-list-item-subtitle
    >

    <div style="width: 300px" v-if="str.data.file_type">
      <video
        :src="str.data.file_path"
        controls="controls"
        style="width: 300px"
        v-if="str.data.file_type == '视频'"
        preload="none"
      ></video>
      <img
        @click="preview(str.data.file_path, str.data.file_path)"
        :src="str.data.file_path"
        v-else
        style="object-fit: contain; width: 300px"
        alt=""
      />
    </div>

    <div style="text-align: right" class="my-2">{{ str.date }}</div>
  </v-list-item-content>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import AI from "@/api/ai.js";
export default {
  props: ["str", "tab"],
  data() {
    return {
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
    };
  },
  methods: {
    getContent(content) {
      let finalContent = content;
      //将大于1个的连续<br>替换为一个<br>，避免过多空行
      finalContent = finalContent.replace(/<br>{1,}/g, "<br>");
      //将大于1个的连续\n替换为一个<br>，避免过多空行
      finalContent = finalContent.replace(/\n{1,}/g, "<br>");
      return finalContent;
    },
    preview(content, title) {
      Bus.$emit("previewFile", { url: this.getContent(content), name: title });
    },
    pr(item) {
      console.log(item);
      if (item.data.loading) {
        return;
      }
      this.$set(item.data, "loading", true);
      this.$set(item.data, "content_cn", "");
      //   item.data.content_cn = item.data.content;
      return new Promise((resolve, reject) => {
        AI.translate({ content: item.data.content })
          .then((res) => {
            resolve(res.data);
            this.$set(item.data, "content_cn", res.data.trans);
            this.$set(item.data, "loading", false);
          })
          .catch((err) => {
            this.$set(item.data, "loading", false);
            this.showToast(err.msg, "danger", 10000);
            reject();
          });
      });
    },
  },
};
</script>
<style scoped>
.badge-danger {
  color: #fff !important;
  background-image: linear-gradient(310deg, #ea0606, #ff667c) !important;
}

.badge-success {
  color: #fff !important;
  background-image: linear-gradient(310deg, #17ad37, #98ec2d) !important;
}

.badge-info {
  color: #fff !important;
  background-image: linear-gradient(310deg, #2152ff, #21d4fd) !important;
}

.badge-warning {
  color: #fff !important;
  background-image: linear-gradient(310deg, #f53939, #fbcf33) !important;
}

.badge-primary {
  color: #fff !important;
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
}
</style>
