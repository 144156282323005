import Request from "@/components/request/js/index.js";

export default {
  //热点
  getHotpot() {
    return Request().get("/api/daily_rank_plate", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //异动
  getMovements() {
    return Request().get("/api/daily_rank_plate/jiucai", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //动量
  getMomentum(category) {
    return Request().get("/api/plate/mom", {
      header: {
        contentType: "application/json",
      },
      data: { category: category },
    });
  },
  //获取动量成分明细
  getMomentumComposition(block_name, category) {
    return Request().get("/api/plate/cons", {
      header: {
        contentType: "application/json",
      },
      data: { block_name: block_name, category: category },
    });
  },
  //获取时间线动量
  getMomTimeLine(category, day, get = 10) {
    return Request().get("/api/plate/mom/calendar", {
      header: {
        contentType: "application/json",
      },
      data: {
        category: category,
        day: day,
        get: get,
      },
    });
  },
  //获取行业指数变动情况
  getIndustryChange(category) {
    return Request().get("/api/plate/mom/change", {
      header: {
        contentType: "application/json",
      },
      data: {
        category: category,
      },
    });
  },
  //获取标签热度
  getTagHeat(category) {
    return Request().get("/api/plate/cons/label_heat", {
      header: {
        contentType: "application/json",
      },
      data: {
        category: category,
      },
    });
  },
  //获取金股页面的信息
  getGoldStockInfo() {
    return Request().get("/api/gold_stock", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //风扇图
  getFanChart(parent = null) {
    let data = {};
    if (parent) {
      data = { parent: parent };
    }
    return Request().get("/api/industry_radar/circle", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //标签风扇图
  getFanChartTag(data) {
    return Request().get("/api/external/raceCircle", {
      header: {
        contentType: "application/json",
      },
      data: data,
    });
  },
  //标签净值图
  getRaceAcc(name) {
    return Request().post("/api/external/raceAcc", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
      },
    });
  },
  //行业全视角表格
  getRadarTable() {
    return Request().get("/api/industry_radar", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //NHNL
  getNHNL() {
    return Request().get("/api/external/nhnl", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
};
