<template>
  <div>
    <div class="">
      <div class="d-flex align-center px-2 py-1">
        <h4 class="text-dark font-weight-600">最近推荐</h4>
      </div>
      <StockRecommend :pageHeight="pageHeight" class="px-2"></StockRecommend>
    </div>

    <!-- <ResearchStatistics></ResearchStatistics> -->

    <v-card color="white" ref="slideRight" class="border-radius-sm mt-4">
      <v-card-title
        class="
          font-weight-bold
          text-h5
          pb-0
          d-flex
          align-center
          justify-space-between
        "
      >
        投资日历
        <v-btn text @click="$router.push({ name: '投资日历' })">
          更多 <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-1">
        <v-list class="pt-0">
          <v-list-item-group>
            <template v-for="(str, index) in TimeLineData">
              <v-list-item :key="str.id">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold"
                    style="
                      overflow: inherit;
                      white-space: normal;
                      line-height: 24px;
                    "
                  >
                    {{ index + 1 + "&nbsp;&nbsp;" }}

                    <span v-if="str.source">
                      <b>{{ str.source }}</b> |
                    </span>
                    {{ str.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row no-gutters>{{ str.content }}</v-row>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="py-2 d-flex align-center justify-space-between"
                  >
                    <v-row no-gutters class="d-flex align-center">
                      <!-- <v-btn-toggle dense borderless v-model="tab" class="zc-toggle"> -->
                      <v-btn
                        elevation="1"
                        small
                        :ripple="false"
                        height="21"
                        class="
                          border-radius-sm
                          text-xxs text-white
                          me-1
                          font-weight-bold
                          px-1
                          py-1
                          badge-font-size
                        "
                        v-for="sector in str.races"
                        :color="colorData[sector.level]"
                        :key="sector.id"
                        @click="$stockHelpers.openTagDetail(sector.id)"
                        >{{ sector.name }}</v-btn
                      >
                      <v-btn
                        elevation="1"
                        small
                        :ripple="false"
                        height="21"
                        class="
                          border-radius-sm
                          text-xxs text-blue
                          me-1
                          font-weight-bold
                          px-1
                          py-1
                          badge-font-size
                        "
                        v-for="sector in str.stocks"
                        :key="sector.id"
                        >{{ sector.name }}</v-btn
                      >
                      <!-- </v-btn-toggle> -->
                    </v-row>

                    <div>{{ str.date }}</div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < TimeLineData.length - 1"
                :key="index"
                light
                style="opacity: 0.5"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
import Timeline from "@/api/timeline.js";
import StockRecommend from "@/views/Dashboard/StockRecommend";
import ResearchStatistics from "./ResearchStatistics.vue";
export default {
  name: "Recommend",
  components: {
    StockRecommend,
    ResearchStatistics,
  },
  data() {
    return {
      TimeLineData: [],
      colorData: { 1: "#f4685d", 2: "#ffad33", 3: "#32bfa7", 0: "#9db7c6" },
      month: "",
      search: "",
      hot: "",
      show: false,
      top: 0,
      pageHeight: 300,
    };
  },
  methods: {
    getDataList(month, search, hot, next) {
      this.month = month;
      this.search = search;
      this.hot = hot;
      if (!month) {
        month = moment().format("YYYY-MM");
      }
      if (search == undefined) {
        search = "";
      }
      if (hot == undefined) {
        hot = "";
      }
      if (!next) {
        this.TimeLineData = [];
        this.curPreMonth = month;
        this.curNextMonth = month;
      }
      this.showLoading("获列表数据中，请稍候！");
      this.isLoading = true;
      Timeline.getTimelineList("", search, hot)
        .then((res) => {
          res.data.forEach((element) => {
            element.showAll = false;
          });
          if (next == "pre") {
            this.TimeLineData = res.data.concat(this.TimeLineData);
          } else {
            this.TimeLineData = this.TimeLineData.concat(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
          this.isLoading = false;
          if (this.first && this.timeline.length > 0) {
            this.getDay();
            this.first = false;
          }
        });
    },
  },
  created() {
    this.getDataList();
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style></style>
